import { useEffect, useCallback, useState, memo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  ExplorerLayout,
  PageTitle,
  UserProfile,
  HeaderLogoWrap,
  Logo,
} from '@gmini/ui-kit'

import * as smApi from '@gmini/sm-api-sdk'

import { useKeycloak } from '@react-keycloak/web'
import { useStore } from 'effector-react'
import { userInfo$ } from '@gmini/common/lib/auth/auth'
import { useNavbar, SwitchProject } from '@gmini/common/lib/components'

import { goals } from '@gmini/common/lib/metrika'

import { getModulesLinkFromEnv } from '@gmini/common'

import { queryParser } from '@gmini/utils'

import { selectedProject$, setSelectedProject } from '../CheckupsExplorer/Model'

import { CheckupsExplorer } from '../CheckupsExplorer'

import { envLinks, seoEventManager, envName } from '../../config'

const PANEL_WIDTH_PX = 350

export const CheckupsExplorerPage = memo(() => {
  const history = useHistory()
  const [panelsCount, setPanelsCount] = useState(1)
  const { keycloak } = useKeycloak()
  const [projectList, setProjectList] = useState<smApi.Project[]>([])
  const [openedSwitchProjectPopup, setOpenedSwitchProjectPopup] = useState(
    false,
  )
  const selectedProjectUrn = queryParser({ key: 'projectUrn' }) as string

  const fetchProjectListPending = useStore(
    smApi.Project.fetchList.defaultContext.pending$,
  )
  const selectedProject = useStore(selectedProject$)

  const userInfo = useStore(userInfo$)

  useEffect(() => {
    if (!selectedProjectUrn && projectList.length) {
      const defaultProject = projectList[0]
      setSelectedProject({ ...defaultProject, userId: userInfo?.id, envName })
      history.replace(
        `${history.location.pathname}?projectUrn=${defaultProject.urn}`,
      )
      return
    }

    const project = projectList.find(p => p.urn === selectedProjectUrn)
    if (project) {
      setSelectedProject({ ...project, userId: userInfo?.id, envName })
    }
  }, [history, projectList, selectedProjectUrn, userInfo?.id])

  useEffect(() => {
    seoEventManager.push({
      event: 'Gtech_Checkup_HomePageView',
      payload: {},
    })

    setTimeout(() => {
      smApi.Project.fetchList.defaultContext.submit()
    }, 0) //TODO Костыль для того чтобы токен успел засетиться в стор перед запросом
  }, [])

  useEffect(() => {
    const fetchProjectSubscription = smApi.Project.fetchList.defaultContext.doneData.watch(
      ({ list }) => {
        setProjectList(list)
      },
    )

    return () => fetchProjectSubscription.unsubscribe()
  }, [])

  const fullName = userInfo ? userInfo.name : ''

  const { Navbar } = useNavbar({
    navModules: getModulesLinkFromEnv(
      envLinks,
      selectedProjectUrn,
      selectedProject?.sourceType,
    ),
    seoEventHandler: (selectedExplorer: string) => {
      seoEventManager.push({
        event: 'ExplorersNavigation_SelectExplorer',
        payload: {
          originalExplorer: 'Проверки',
          selectedExplorer,
        },
      })
    },
    title: 'Проверки информационной модели',
    icon: (
      <HeaderLogoWrap>
        <Logo />
      </HeaderLogoWrap>
    ),
  })

  const onCheckupOpen = useCallback(
    ({ id }: { readonly id: number }) => history.push(`/checkup/${id}`),
    [history],
  )

  const onLogout = useCallback(() => {
    keycloak.logout()
  }, [keycloak])

  return (
    <>
      <PageTitle>Каталог проверок</PageTitle>
      <ExplorerLayout
        navBar={Navbar}
        switchProjectSelect={
          <SwitchProject
            projectList={projectList}
            disabled={fetchProjectListPending}
            loading={fetchProjectListPending}
            onChangeProject={(project: smApi.Project) => {
              setSelectedProject({ ...project, userId: userInfo?.id, envName })
              history.replace(
                `${history.location.pathname}?projectUrn=${project.urn}`,
              )
            }}
            selectedProjectName={selectedProject?.name || ''}
            onToggle={() => setOpenedSwitchProjectPopup(prev => !prev)}
            opened={openedSwitchProjectPopup}
            onClose={() => setOpenedSwitchProjectPopup(false)}
          />
        }
        panelsCount={panelsCount + 1}
        panelWidth={PANEL_WIDTH_PX}
        headerRightSide={
          <UserProfile
            userName={fullName}
            onLogout={onLogout}
            onProfileClick={() => goals.showUserMenu()}
            email={userInfo?.email}
          />
        }
        body={
          <CheckupsExplorer
            panelWidth={PANEL_WIDTH_PX}
            onCheckupOpen={onCheckupOpen}
            panelsCount={setPanelsCount}
            selectedProject={selectedProject}
          />
        }
      />
    </>
  )
})

CheckupsExplorerPage.displayName = 'CheckupsExplorerPage'
